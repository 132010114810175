import React from "react";
import UserInfo from "./user";

class Profile extends React.Component {
    render() {
        return (
         <div>
            <UserInfo />
         </div>
        );
     }
};

export default Profile;
