import React from "react";
import { Link } from "react-router-dom";
import LoginButton from "../auth/loginButton";
import LogoutButton from "../auth/logoutButton";
import {useAuth0} from "@auth0/auth0-react";

interface Props {
  loggedIn?: boolean;
}

const Nav: React.FC<Props> = ({ loggedIn }) => {
    const { isAuthenticated, logout, user } = useAuth0();
    const { picture } = user ? user : '';
  return (
      <header className="section rd-navbar-wrap">
          <nav className="rd-navbar">
              <div className="navbar-container">
                  <div className="navbar-cell">
                      <div className="navbar-panel">
                          <button className="navbar-switch"
                                  data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","isolate":"[data-multi-switch]"}'></button>
                          <div className="navbar-logo"><Link className="navbar-logo-link" to="/"><img
                              className="navbar-logo-default"
                              src="media/logo-113x30.svg" alt="Neon" width="113" height="30"/></Link>
                          </div>
                      </div>
                  </div>

                  <div className="navbar-cell">
                      <div className="navbar-subpanel">
                          <div className="navbar-subpanel-item">
                              <button className="navbar-button navbar-info-button mdi-dots-vertical"
                                      data-multi-switch='{"targets":".rd-navbar","scope":".rd-navbar","class":"navbar-info-active","isolate":"[data-multi-switch]"}'></button>
                              <div className="navbar-info">
                                  {isAuthenticated ? (
                                      <span>
                                          <Link to="/profile">
                                          <img
                                              src={picture}
                                              alt="Profile"
                                              className="quote-author-image rounded-circle"
                                              width="64" height="64"
                                          />
                                          </Link>
                                      <LogoutButton/>
                                      </span>
                                  ) : <LoginButton text="Subscribe"/>}

                              </div>
                          </div>
                      </div>
                  </div>



              </div>
          </nav>
      </header>
  );
};
export default Nav;
