import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useApi = (
    url: string,
    hash: any,
    options: any = {}
): { error?: Error | null; loading: boolean; data?: any } => {
    const { getAccessTokenSilently } = useAuth0();
    const [state, setState] = useState({
        error: null,
        loading: true,
        data: null,
    });

    useEffect(() => {
        (async () => {
            try {
                const { audience, scope, ...fetchOptions } = options;
                const accessToken = await getAccessTokenSilently({ audience, scope });
                // console.log(accessToken)
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}` },
                    body: JSON.stringify({ text: hash })
                };
                const res = await fetch(url, requestOptions);
                const d = await res.json();
                setState({
                    ...state,
                    data: d,
                    error: null,
                    loading: false,
                });
            } catch (error) {
                setState({
                    ...state,
                    error,
                    loading: false,
                });
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return state;
};
