import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface PropTypes {
    text: string;
}

const LoginButton = ({text}: PropTypes) => {
    const { loginWithRedirect } = useAuth0();

    return <button className="btn btn-sm" onClick={() => loginWithRedirect()}>{text}</button>;
};

export default LoginButton;
