import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {HOST} from "../home";
import {useApi} from "./use-api";
import {Loading} from "../App/Loading";
import { Error } from "../App/Error";

const UserInfo = () => {
    const { user } = useAuth0();
    const { name, picture, email } = user;
    const { loading, error, data: res } = useApi(
        `${HOST}/services`,
        '65e84be33532fb784c48129675f9eff3a682b27168c0ea744b2cf58ee02337c5',
        {
            audience: 'https://security-api.airadars.com/services',
            scope: 'search:service',
        }
    );

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <Error message={error.message} />;
    }

    return (
        <div>
            <div className="row align-items-center profile-header">
                <div className="col-md-2 mb-3">
                    <img
                        src={picture}
                        alt="Profile"
                        className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                    />
                </div>
                <div className="col-md text-center text-md-left">
                    <h2>{name}</h2>
                    {res && (<h2>{res}</h2>)}
                    <p className="lead text-muted">{email}</p>
                </div>
            </div>
            <div className="row">
        <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(user, null, 2)}
        </pre>
            </div>
        </div>
    );
};

export default UserInfo;
