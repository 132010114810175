import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {useAuth0} from "@auth0/auth0-react";
import DetailButton from "../auth/detailButton";

export const HOST = 'https://security-api.airadars.com';
// export const HOST = 'https://localhost';

interface PropTypes {
  text: string;
  searchText: string;
  isLoaded: boolean;
  count: number;
  err: string;
  stat: any[];
}

class Home extends React.Component<any, PropTypes> {
  constructor(props: any) {
    super(props);
    this.state = {
      text: '',
      searchText: '',
      isLoaded: true,
      count: -1,
      err: '',
      stat: [],
    };

  }

  async componentWillMount() {
    await this.loadStat();
  }

  loadStat = async () => {
    const json =  await fetch(HOST + '/stat')
        .then(response => response.json());
    this.setState({ stat: json });
  }



  handleChange = async (event:any) => {
    let textValue = event.target.value;
    this.setState({text: textValue});
    if (textValue.length !== 64) {
      textValue = await this.convertToHash(textValue);
    }
    this.setState({searchText: textValue});
  }

  convertToHash = async (message: string) => {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex;
  }

  handleSubmit = (event:any) => {
    const requestOptions = {
      // mode: 'cors',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: this.state.searchText })
    };
    this.setState({ isLoaded: false });

    fetch(HOST + '/hashes', requestOptions)
        .then(response => response.json())
        .then(data => {
          this.setState({ count: data.count });
          this.setState({ isLoaded: true });
          this.setState({ err: '' });
        },
            (error) => {
              this.setState({ isLoaded: true });
              this.setState({ err: 'error' });
            }
            );

    event.preventDefault();
  }

  render() {
    const { count, isLoaded, err, stat, searchText } = this.state;
  return (

      <div>

        <div className="modal fade" id="stat-modal1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {stat.length > 0 && <h4>{stat[0].title}</h4> }

                {stat.length > 0 && <p className="bigger">{stat[0].details}</p>}
              </div>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
            </div>
          </div>
        </div>

        <div className="modal fade" id="stat-modal2" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {stat.length > 0 && <h4>{stat[1].title}</h4> }

                {stat.length > 0 && <p className="bigger">{stat[1].details}</p>}
              </div>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
            </div>
          </div>
        </div>

        <div className="modal fade" id="stat-modal3" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                {stat.length > 0 && <h4>{stat[2].title}</h4> }

                {stat.length > 0 && <p className="bigger">{stat[2].details}</p>}
              </div>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
            </div>
          </div>
        </div>

        <section className="section section-lg" id="contacts">
          <div className="container">
            <div className="row row-30 align-items-xl-center justify-content-xxl-between">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="box px-xl-3 px-xxl-4">
                <h2>Search data</h2>
                <p className="bigger">You can search your data leaks in the pool of known data breaches. The data is
                  hashed so
                  we don't have the source. Enter the hash of your data to search. For more details look into ...</p>
                <form className="rd-mailform" data-form-output="form-output-global" data-form-type="contact"
                      onSubmit={this.handleSubmit}>
                  <div className="row row-30">
                    <div className="col-xs-12 col-xl-10">
                      <div className="form-group">
                        <input className="form-control" type="text" name="text" onChange={this.handleChange}
                               placeholder="Enter hash to search"
                               data-constraints="@Required"/>
                      </div>
                    </div>
                    <div className="col-xs-12 col-xl-2">
                      {isLoaded &&
                      <button className="btn" type="submit">Search</button>
                      }
                      <ClipLoader color='#d7e1ec' loading={!isLoaded} size={60}/>

                    </div>
                  </div>

                </form>
              </div>
              </div>
              {count > -1 &&
              <div className="col-md-12 col-lg-12 col-xl-12 animated fadeIn">
                <div className="box px-xl-3 px-xxl-4">
                  <div className="row row-30">
                    <div className="col-xs-12 col-xl-10">
                      <div className="form-group">
                        <p>{count} matches found</p>

                      </div>
                    </div>
                    <div className="col-xs-12 col-xl-2">
                      <DetailButton hash={searchText}/>
                    </div>
                  </div>

                </div>
              </div>
              }

              {err !== '' &&
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="box px-xl-3 px-xxl-4">
                  <div className="row row-30">
                    <div className="col-xs-12 col-xl-10">
                      <div className="form-group">
                        <p>Error: {err}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              <div className="col-xxl-10">
                <div className="group-20 d-sm-flex align-items-sm-end justify-content-sm-between text-center">
                  <h2>Statistics</h2>

                </div>
                <div className="pricing-group offset-md" id="pricing-group">

                  <div className="owl-carousel owl-style-1 owl-item-end"
                       data-owl="{&quot;loop&quot;:false,&quot;mouseDrag&quot;:false,&quot;dots&quot;:true,&quot;autoplay&quot;:false,&quot;responsive&quot;:{&quot;768&quot;:{&quot;items&quot;:2},&quot;992&quot;:{&quot;items&quot;:3},&quot;1600&quot;:{&quot;items&quot;:3,&quot;margin&quot;:100}}}">

                    <article key="stat1" className="pricing-modern pricing-success block block-sm block-center"
                             data-animate='{"class":"fadeInUp"}'>
                      {stat.length > 0 && <div className="pricing-modern-title h4">{stat[0].title}</div> }
                      <div className="pricing-modern-divider">
                        <hr className="divider"/>
                      </div>
                      <div className="pricing-modern-details">
                        {stat.length > 0 && <span className="pricing-modern-price h4">{stat[0].value}</span> }
                      </div>
                      <div className="pricing-modern-btn">
                        <button className="btn btn-sm" data-modal-trigger='{"target":"#stat-modal1"}'>Details</button>
                      </div>
                    </article>

                    <article key="stat2" className="pricing-modern pricing-success block block-sm block-center"
                             data-animate='{"class":"fadeInUp"}'>
                      {stat.length > 0 && <div className="pricing-modern-title h4">{stat[1].title}</div> }
                      <div className="pricing-modern-divider">
                        <hr className="divider"/>
                      </div>
                      <div className="pricing-modern-details">
                        {stat.length > 0 && <span className="pricing-modern-price h4">{stat[1].value}</span> }
                      </div>
                      <div className="pricing-modern-btn">
                        <button className="btn btn-sm" data-modal-trigger='{"target":"#stat-modal2"}'>Details</button>
                      </div>
                    </article>

                    <article key="stat3" className="pricing-modern pricing-success block block-sm block-center"
                             data-animate='{"class":"fadeInUp"}'>
                      {stat.length > 0 && <div className="pricing-modern-title h4">{stat[2].title}</div> }
                      <div className="pricing-modern-divider">
                        <hr className="divider"/>
                      </div>
                      <div className="pricing-modern-details">
                        {stat.length > 0 && <span className="pricing-modern-price h4">{stat[2].value}</span> }
                      </div>
                      <div className="pricing-modern-btn">
                        <button className="btn btn-sm" data-modal-trigger='{"target":"#stat-modal3"}'>Details</button>
                      </div>
                    </article>


                    {/* <article className="pricing-modern pricing-success block block-sm block-center"*/}
                    {/*                             data-animate='{"className":"fadeInUp"}'>*/}

                    {/*  {stat.length > 0 && <div className="pricing-modern-title h4">{this.state.stat[0].title}</div>}*/}
                    {/*  <div className="pricing-modern-divider">*/}
                    {/*    <hr className="divider"/>*/}
                    {/*  </div>*/}
                    {/*  <div className="pricing-modern-details">*/}
                    {/*    <span className="pricing-modern-price h4">254k</span>*/}
                    {/*  </div>*/}
                    {/*  <div className="pricing-modern-btn">*/}
                    {/*    <button className="btn btn-sm" data-modal-trigger='{"target":"#modal-evidences"}'>Details*/}
                    {/*    </button>*/}
                    {/*  </div>*/}
                    {/*</article>*/}


                  </div>
                </div>
              </div>


              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="box px-xl-3 px-xxl-4">
                  <div className="row row-30">

                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <h4>How does it work?</h4>
                      <table className="table table-sm table-responsive-xl table-no-bordered bigger">
                        <tbody>
                        <tr>
                          <td className="text-right text-600 pl-0 align-middle">What we store?</td>
                          <td className="biggest">We searching and processing data breaches and saving only
                            'fingerprints'. Let us repeat, we are not storing the source data. We process it on the edge
                            and store only fingerprints: hashes, model weights (images, prediction models).
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right text-600 pl-0">What should you search?</td>
                          <td><a className="link-inherit" href="mailto:#">Try search for some clear identification data:
                            passport number, surname (if you have quite a fancy one), email address, bank id, passwords
                            you
                            using.</a></td>
                        </tr>
                        <tr>
                          <td className="text-right text-600 pl-0">How to get a fingerprint for the data?</td>
                          <td>For the text data - just use SHA256 to hash for the text. <br/>
                            <ul className="list list-marked list-secondary">
                              <li className="list-item">Sample command line: echo -n "SOME_TEXT" | openssl dgst
                                -sha256
                              </li>
                              <li className="list-item"><a href="https://emn178.github.io/online-tools/sha256.html">External
                                online tool</a></li>
                              <li className="list-item">Any tool of your choise, just make sure that we have something
                                like
                                '6161b0a284159565a0f7d5df2dd2698b5f87906cd91ff5322caf179b451f5a41' as a result.
                              </li>
                            </ul>


                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div className="form-output snackbar snackbar-secondary" id="form-output-global"></div>
        </section>
      </div>
  )
}

}

export default Home;
