import React from "react";
import {
    useParams
} from "react-router-dom";
import {useApi} from "./use-api";
import {HOST} from "../home";
import {Loading} from "../App/Loading";
import {Error} from "../App/Error";
import {months} from "../../const/month";



function DetailsSearc() {
    const { hash } = useParams();

    const { loading, error, data: services } = useApi(
        `${HOST}/services`,
        hash,
        // {
        //     audience: 'https://security-api.airadars.com/services',
        //     scope: 'search:service',
        // }
    );

    const { loading: loading2, error: error2, data: countries } = useApi(
        `${HOST}/countries`,
        hash,
        // {
        //     audience: 'https://security-api.airadars.com/countries',
        //     scope: 'search:service',
        // }
    );

    const { loading: loading3, error: error3, data: metadata } = useApi(
        `${HOST}/metadata`,
        hash,
        // {
        //     audience: 'https://security-api.airadars.com/countries',
        //     scope: 'search:service',
        // }
    );

    // if (loading || loading2) {
    //     return <Loading />;
    // }

    if (error) {
        return <Error message={error.message} />;
    }

    if (error2) {
        return <Error message={error2.message} />;
    }

    if (error3) {
        return <Error message={error3.message} />;
    }

    let uniqueCountries:any[] = [];
    if(countries) {
        const s = new Set(countries);
        uniqueCountries = Array.from(s);
        uniqueCountries.sort();
    }
    let uniqueDates:any[] = [];
    if (metadata && metadata.dates) {
        const sd = new Set();
        const rawDate = [];
        for (let i = 0; i < metadata.dates.length; i++) {
            const d = Date.parse(metadata.dates[i]);
            rawDate.push(d);
        }
        rawDate.sort();
        for (let k = 0; k < rawDate.length; k++) {
            const d = new Date(rawDate[k]);
            const monthIndex = d.getMonth()
            const dt = d.getFullYear() + ' ' + months[monthIndex];
            sd.add(dt)
        }
        uniqueDates = Array.from(sd);
    }



    return (
       <div>
           <h1>{hash}</h1>
           <h2>Services</h2>
           {services && services.map(function(service:any){
               if (service.name.length > 0) {
                   return <p>{service.name + ': ' + service.line_type}</p>;
               } else {
                   return <p>{service.line_type}</p>;
               }

           })}
           <h2>Countries</h2>
           {uniqueCountries && uniqueCountries.map(function(country:string){
               return <p>{country}</p>;
           })}
           <h2>Dates</h2>
           {uniqueDates && uniqueDates.map(function(date:any){
               return <p>{date}</p>;
           })}
       </div>
    );
};

// const uniqueArray = (a: []) => {
//         a.filter(function (item, pos) {
//             return a.indexOf(item) == pos;
//         })
// }

export default DetailsSearc;
