import React from 'react';
import ReactDOM from 'react-dom';
import App, { history } from './components/App/App';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

const onRedirectCallback = (appState: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    history.replace((appState && appState.returnTo) || window.location.pathname);
};

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain="itwillrock.auth0.com"
            clientId="JRCTEYrQQ60WLIHfzCueTsKsHLAelKVM"
            redirectUri={window.location.origin}
            audience={process.env.REACT_APP_AUDIENCE}
            scope="read:users"
            onRedirectCallback={onRedirectCallback}
        >
            <App />
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
