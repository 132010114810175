import React from "react";
import UserInfo from "./user";
import DetailsSearc from "./details-search";
import ClipLoader from "react-spinners/ClipLoader";
import DetailButton from "./detailButton";

class Details extends React.Component {
    render() {
        return (
         <div>
             <section className="section section-lg" id="contacts">
                 <div className="container">
                     <div className="row row-30 align-items-xl-center justify-content-xxl-between">
                         <div className="col-md-12 col-lg-12 col-xl-12">
                             <div className="box px-xl-3 px-xxl-4">
                                 <DetailsSearc />
                             </div>
                         </div>

                     </div>
                 </div>
                 <div className="form-output snackbar snackbar-secondary" id="form-output-global"></div>
             </section>

         </div>
        );
     }
};

export default Details;
