import * as React from "react";
import { Route, Router, Switch } from 'react-router-dom';
import Home, {HOST} from '../../components/home/index';
import { createBrowserHistory } from 'history';
import Nav from "../navigation";
import Profile from "../auth/profile";
import { ProtectedRoute } from './ProtectedRoute';
import { useAuth0 } from '@auth0/auth0-react';
import { Error } from './Error';
import { Loading } from './Loading';
import Details from "../auth/details";

export const history = createBrowserHistory();

function App() {
  const { isLoading, error } = useAuth0();

    return (
        <Router history={history}>
            <Nav />
          {error && <Error message={error.message} />}
              <Switch>
                <Route exact path="/" component={Home} />
                <ProtectedRoute path="/profile" component={Profile} />
                <ProtectedRoute path="/details/:hash" component={Details} />
                {/*<Route path="/questions" component={XxxQuestionsPage} />*/}
                {/*<Route component={XxxPageNotFoundPage} />*/}
              </Switch>

          </Router>

    );
}

export default App;
