import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import LoginButton from "./loginButton";

interface PropTypes {
    hash:string;
}

const DetailButton = ({hash}:PropTypes) => {
    const {isAuthenticated} = useAuth0();

    return (
        <span>
        {isAuthenticated ? (
            <Link to={`/details/${hash}`}>
                <button className="btn" type="submit">Details</button>
            </Link>
        ) : <LoginButton text="Details"/>}
            </span>
    );
};

export default DetailButton;
